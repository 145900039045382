<template>
  <div>
    <w-top-bar>Pagos a Autores</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div class="border-b border-gray-400 mb-2">
        <div class="flex items-center">
          <div class="flex-1 h-12 text-gray-900 items-center hidden md:flex">
            Resumen General
          </div>
          <!-- <w-date-range
            class="flex-1 h-12"
            v-model="dateFilter"
            @input="reload()"
          /> -->
        </div>
      </div>
      <div class="grid gap-4 grid-cols-1 sm:grid-cols-3 my-2 w-full">
        <div
          class="bg-white dark:bg-gray-900 border border-gray-400 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full"
          style="cursor: auto"
        >
          <a
            aria-label="Unsplash Downloads"
            target="_blank"
            rel="noopener noreferrer"
            href="https://stackdiary.com/"
          >
            <div
              class="flex items-center text-gray-900 dark:text-gray-100 text-sm"
              style="cursor: auto"
            >
              Total en Pesos
            </div>
          </a>
          <p
            class="mt-2 text-2xl font-bold spacing-sm text-black dark:text-white"
            style="cursor: auto"
          >
            5,412 ARS
          </p>
          <!-- Subtitulo -->
          <p
            class="text-sm text-gray-600 dark:text-gray-400"
            style="cursor: auto"
          >
            1500 ventas
          </p>
        </div>
        <div
          class="bg-white dark:bg-gray-900 border border-gray-400 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full"
          style="cursor: auto"
        >
          <a
            aria-label="Unsplash Downloads"
            target="_blank"
            rel="noopener noreferrer"
            href="https://stackdiary.com/"
          >
            <div
              class="flex items-center text-gray-900 dark:text-gray-100 text-sm"
              style="cursor: auto"
            >
              Total en Dolares
            </div>
          </a>
          <p
            class="mt-2 text-2xl font-bold spacing-sm text-black dark:text-white"
            style="cursor: auto"
          >
            5,412 ARS
          </p>
          <!-- Subtitulo -->
          <p
            class="text-sm text-gray-600 dark:text-gray-400"
            style="cursor: auto"
          >
            1500 ventas
          </p>
        </div>
        <div
          class="bg-white dark:bg-gray-900 border border-gray-400 dark:border-gray-800 rounded-lg p-4 max-w-72 w-full"
          style="cursor: auto"
        >
          <a
            aria-label="Unsplash Downloads"
            target="_blank"
            rel="noopener noreferrer"
            href="https://stackdiary.com/"
          >
            <div
              class="flex items-center text-gray-900 dark:text-gray-100 text-sm"
              style="cursor: auto"
            >
              Total Unidades
            </div>
          </a>
          <p
            class="mt-2 text-2xl font-bold spacing-sm text-black dark:text-white"
            style="cursor: auto"
          >
            1,250
          </p>
          <!-- Subtitulo -->
          <p
            class="text-sm text-gray-600 dark:text-gray-400"
            style="cursor: auto"
          >
            <!-- 1500 ventas -->
          </p>
        </div>
      </div>
      <table
        class="min-w-full overflow-hidden divide-y divide-gray-200 rounded-lg"
      >
        <thead class="hidden md:table-header-group print:table-header-group">
          <tr>
            <th
              class="py-3 pr-6 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
            >
              Nombre
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase md:w-32 print:hidden md:table-cell bg-gray-50"
            >
              ID Pedido
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500 uppercase print:hidden bg-gray-50"
            >
              Cantidad
            </th>
            <th
              class="w-32 px-4 py-3 text-xs font-medium leading-4 tracking-wider text-right text-gray-500 uppercase print:hidden bg-gray-50"
            >
              Pago
            </th>
          </tr>
        </thead>
        <tbody
          class="flex-1 bg-white divide-y divide-gray-300 sm:flex-none print:flex-none"
        >
          <tr
            v-for="item in items"
            :key="item.orderId"
            class="flex flex-col mb-2 flex-no wrap sm:table-row print:table-row sm:mb-0"
          >
            <td class="py-3 overflow-hidden whitespace-no-wrap">
              <div class="pl-2">
                <div class="text-xs text-gray-900 md:text-sm">
                  {{ item.nombre | limpiarNombre }}
                </div>
                <div class="text-xs text-gray-500 md:text-sm">
                  {{ item.email }}
                </div>
                <div class="text-xs text-gray-500 md:text-sm">
                  {{ item.telefono }}
                </div>
              </div>
            </td>
            <td class="px-6 md:py-3 text-sm">
              <div class="no-wrap text-xs w-32">{{ item.orderIdCode }}</div>
            </td>
            <td
              class="px-6 py-4 text-center text-sm whitespace-no-wrap md:table-cell"
            >
              {{ item.cantidad || 1 }}
            </td>
            <td class="whitespace-no-wrap text-xs text-right px-4">$2000</td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="mt-10">
        <div v-if="dashboardStat">
          <w-total-sales
            :loading="isGetDashboardStatPending"
            :chart="dashboardStat.totalsByDay"
            :currency="currency"
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      items: [],
      isGetDashboardStatPending: false,
      currency: "ARS",
    };
  },
  created() {
    this.obtenerCompras();
  },
  methods: {
    ...mapActions("pagos-autores", ["find"]),
    async obtenerCompras() {
      console.log("obtenerCompras");
      this.items = await this.find();
    },
  },
  filters: {
    limpiarNombre(value) {
      if (!value) return "";
      //   Remover doble espacios:
      value = value.toString().replace(/\s\s+/g, " ");
      //   Capitalizar todas las palabras
      value = value.toString().toLowerCase().trim();
      //   Para cada palabra, capitalizar la primera letra
      value = value
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return value;
    },
  },
};
</script>

<style>
</style>